/* ==========================================================================
General
========================================================================== */
#photoalbum{
  background-color: #f4f4f4;
  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      margin: 5px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      a{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
      .layers{
        display: none;
      }
      .img-responsive {
        border: 7px solid #fff;
        max-height: 180px;
        width: 100%;
      }
    }
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #photoalbum {
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #photoalbum {
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #photoalbum{

  }
}