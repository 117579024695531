/* ==========================================================================
General
========================================================================== */
#menu{
  height: 100px;
  background-color: #000000;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  #mobile-btn {
    display: none;
    cursor: pointer;
    z-index: 99999;
    width: 30px;
    float: right;
    margin: 0 30px;
    height: 70px;
    position: relative;
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    span {
      display: block;
      position: absolute;
      height: 3px;
      border-radius: 30px;
      width: 100%;
      max-width: 100%;
      background: #7a7a7a;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 23px;
      }
      &:nth-child(2) {
        top: 33px;
      }
      &:nth-child(3) {
        top: 43px;
      }
    }
    &.open {
      span {
        &:nth-child(1) {
          top: 35px;
          transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }
        &:nth-child(3) {
          top: 35px;
          transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }
    }
  }
  .logo{
    padding: 20px;
    }
    .bigicons {
      float:right;
      display: none;
      a {
        text-decoration: none;
      }
      .home {
        &::before {
          line-height: 53px;
          padding: 0 0 15px 25px;
          content: '\F015';
          font-family: FontAwesome, sans serif;
          font-size: 30px;
          color: #fff;
        }
      }
      .phone {
        &::before {
          line-height: 53px;
          padding: 0 0 15px 25px;
          content: '\F095';
          font-family: FontAwesome, sans serif;
          font-size: 30px;
          color: #fff;
        }
      }
    }
   .left{
     float:left;
   }
  .right {
   float:right;
    position: relative;
    .icons {
      position: absolute;
      left:-50px;
      top:35px;
      a {
        text-decoration: none;
      }
      .home {
        &::before {
          padding: 0 5px 0 5px;
          content: '\f015';
          font-family: FontAwesome, sans serif;
          font-size: 18px;
          color: #fff;
        }
      }
    }
    .phone {
      &::before {
        padding: 0 25px 0 0;
        content: '\f095';
        font-size: 20px;
        font-family: FontAwesome, sans serif;
        color: #fff;
      }
    }
    ul {
      list-style: none;
      position: relative;
      float:left;
      padding: 0;
      margin: 0;
      li {
        position: relative;
        float: left;
        margin:0;
        padding:37px 0 10px 17px;
        &.active > a, &.active > span {
          font-family: Roboto-Bold, sans-serif;
          border-bottom: 2px solid #575757;
        }
        a, span {
          font-size: 14px;
          font-family: Roboto-Regular, sans-serif;
          text-decoration: none;
          color: #ffff;
          &:hover{
            color: #ababab;
            transition: 0.5s ease;
          }
        }
        ul {
          z-index: 999;
          display: none;
          position: absolute;
          top: 60px;
          left: 10px;
          padding: 5px 0 10px 10px;
          margin: 0;
          width: 200px;
          border-left: 2px solid #ffffff;
          background-color: #000000;
          animation-name: fadeIn;
          animation-duration: 1s;
          animation-iteration-count: 1;
          li {
            max-width: 200px;
            padding:0;
            display: block;
            word-break: break-all;
            float:none;
            a{
              padding:5px 2px 5px 2px;
              line-height: 1.8;
            }
          }
        }
        &:hover ul{
          display: block;
          text-decoration: none;
        }
      }
      &::after {
        content: '';

        clear: both;
      }
    }
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #menu{
    height:67px;
    &.nav-up {
      top: -100px;
      transition: 0.3s ease;
      position: fixed;
      animation: none;
    }
    &.nav-down {
      top: 0;
      transition: 0.3s ease;
      position: fixed;
      animation: none;
    }

    #mobile-btn {
      display: block;
    }
    &.open {
      top: 0 !important;
      transition: 0.3s ease;
    }
    .logo{
      padding: 10px;
      img{
        max-height: 50px;
      }
    }
    .bigicons {
      display: block;
    }
    .right {
      position:absolute;
      right: 0;
      display: none;
      top:65px;
      left:0;
      z-index: 999;
      width: 100%;
      height: 450px;
      background-color: rgba(0, 0, 0, 0.3);
      animation-name: fadeOut;
      animation-duration: 2s;
      .icons {
        display: block;
      }
      ul{
        margin: 0;
        width: 300px;
        height: auto;
        text-align: center;
        background-color: black;
        padding: 0 50px 0 0;
        float:none;
        list-style-type: none;
        position: absolute;
        right: -70px;
        top:0;
        z-index: 999;
        animation-name: slideOutLeft;
        animation-duration: 2s;
        li{
          float:none;
          text-align: left;
          padding: 10px 60px 10px 20px;
          a, span {
            font-size: 19px;
          }
          ul {
            display: block;
            position: static;
            padding-left: 20px;
            li {
              position: static;
              float: none;
              a, span {
                font-size: 14px;
                display: block;
              }
            }
          }
        }
      }
      &.active{
        display: unset;
        animation-name: fadeIn;
        animation-duration: 2s;
        ul{
          animation-name: slideInRight;
          animation-duration: 2s;
        }
      }
    }
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #menu{

  }
}