/* ==========================================================================
General
========================================================================== */
#header{
  background-image: url("../images/header-sharp.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position-y: 100%;
  .header-wrapper{
    margin: 0;
    padding: 500px 0 50px 0;
    position: relative;
    .headertitle {
      text-align: left;
      float:left;
      h4 {
        font-weight: 200;
        text-transform: uppercase;
        text-align: left;
        color: #fff;
        font-size: 21px;
        span {
          font-weight: bold;
          border-bottom:2px solid rgba(255, 255, 255, 0.8);
        }
      }
      h2 {
        font-weight: 100;
        text-transform: uppercase;
        color: #fff;
        font-size: 58px;
        span {
          font-weight: bold;
        }
      }
    }
  }
  .opening{
    float: right;
    text-align: right;
    margin: 130px 0 -90px 0;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 11px;
    border-radius: 4px;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    .header{
      font-family: Roboto-Medium, sans-serif;
      font-weight: 900;
      font-size: 16px
    }
    p{
      line-height: 1.2;
      font-size: 20px;
      padding: 0;
      margin: 0;
      font-family: Roboto-Regular, sans-serif;
    }
    .checkmark-green{
      max-width: 250px;
      width: 100%;
      margin: 20px 0 0 0
    }
  }
    .pop-up {
      background-color: black;
      padding: 13px 36px 13px 36px;
      position: fixed;
      right: -142px;
      color: #ffff;
      text-decoration: none;
      font-family: Roboto-Regular, sans-serif;
      transform: rotate(270deg);
      border: 2px solid #ffff;
      top: 446px;
      transition: 1s ease;
      cursor: pointer;
      z-index: 999;
      &.open {
        transition: 2s ease;
      }
      &:hover{
        transition: 0.5s ease;
        color: #cbcbcb;
      }
    }
    .showform{
      display: none;
      max-height: 339px;
      border: 2px solid #ffffff;
      height: 100%;
      max-width: 300px;
      position: fixed;
      animation-name: slideOutRight;
      animation-duration: 2s;
      right:52px;
      top: 304px;
      z-index: 999999;
      padding: 30px 20px 40px 20px;
      background-color: black;
      input{
        margin: 5px;
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-family: Roboto-Regular, sans-serif;
        color: #000000;
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: Roboto-Regular, sans-serif;
        color: #000000;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        font-family: Roboto-Regular, sans-serif;
        color: #000000;
      }
      label{
        display: none;
      }
      p{
        font-size: 10px;
        color: #fff;
        padding: 0;
        margin: 0;
      }
      .jb-submit{
        border-radius: 5px;
        background-color: #000000;
        border: 1px solid #ffffff;
        color:white;
        padding: 10px;
        font-family: Roboto-Regular, sans-serif;
        transition: 0.5s ease;
        &:hover{
          background-color: #ffffff;
          color: #000000;
          transition: 0.5s ease;
        }
      }
      h3{
        color:#ffff;
        font-size: 23px;
      }
      &.active{
        display: unset;
        animation-name: slideInRight;
        animation-duration: 2s;
      }

      // Form Succes Bericht
      .alert-success{
        padding: 5px 5px 5px 10px;
      }
    }
}
/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1800px) {
  #header{
    .opening{
      background-color: rgba(255, 255, 255, 0.3);
      padding: 11px;
      border-radius: 4px;
      animation-name: fadeIn;
      animation-duration: 0.5s;
    }
  }

}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #header{
    .header-wrapper{
      .headertitle{
        h2{
          font-size: 63px;
        }
      }
    }
    .opening{
      background-color: rgba(255, 255, 255, 0.5);
      padding: 11px;
      border-radius: 4px;
    }
  }

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #header{
     .header-wrapper{
       .headertitle{
         h4{
           font-size: 44px;
         }
         h2{
           font-size: 48px;
         }
       }
     }
     .opening{
     }
   }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
    #header{
      .header-wrapper{
        padding: 100px 0 50px 0;
        .headertitle{
          text-align: center;
          h4{
            text-align: center;
            font-size: 24px;
          }
          h2{
            font-size: 28px;
          }
        }
      }
      .opening{
      }
      .pop-up{
        padding: 5px 34px 5px 34px;
        position: fixed;
        right: -153px;
        top: 226px;
      }
      .showform{
        max-height: 333px;
        top: 78px;
        right: 31px;
      }
    }

  }
