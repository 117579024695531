/* ==========================================================================
General
========================================================================== */
#advice{
  padding: 0 0 105px 0;
  .line{
    height: 5px;
    width: 100%;
    background-color: #f9f9f9;
  }
  .service-wrapper{
    text-align: center;
    h3{
      padding: 97px 0 50px 0;
      font-family: Roboto-Regular, sans-serif;
      font-style: italic;
      text-transform: unset;
      font-size: 27.5px;
    }
    a{
      color:#fff;
    }
    p{
      float:right;
    }
    .times {
      text-align: left;
      margin: -11px 0 0 -25px;
      span {
      }
    }
  }

}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #advice{
    .service-wrapper{
      .times{
        margin: -10px 0 0 0;
      }
    }

  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #advice{
    .service-wrapper{
      .times{
        text-align: center;
        margin: 30px 0 0 0;
      }
    }

  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #advice{


  }
}