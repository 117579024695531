/* ==========================================================================
General
========================================================================== */

// Sidebar static page
#sidebar{
  .headermenu {
    position: relative;
    background-color: #f4f4f4;
    padding: 30px 30px 30px 30px;
    border: 5px solid #ffffff;
    margin: -200px 0 0 0;
    .news-return{
      position: absolute;
      top:0;
      right:0;
      left:0;
      padding: 15px 10px 10px 25px;
      background-color: #000000;
      h4{
        font-size: 12px;
        color:#fff;
      }
    }
    .bold{
      font-size: 19px;
      margin: 20px 0 0 0;
    }
    ul {
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      list-style-type: none;
      a {
        text-decoration: none;
        color: #000000;
        li {
          border-bottom: 1px solid #cdcdcd;
          padding: 12px 0 17px 0;
          font-size: 17px;
          span {
            float: right;
            .fa {
              font-weight: bold;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
// Contact
#sidebar-contact{
  background-color: #f4f4f4;
  padding: 20px 30px 30px 30px;
  border-right: 5px solid #ffffff;
  border-left: 5px solid #ffffff;
  p{
    padding: 10px 0 0 0;
    margin: 0 0 0 0;
    line-height: 20px;
  }
  input{
    margin: 10px 0 20px 0;
  }
  a{
    margin: 30px 0 0 0;
  }
  .bold{
    font-size: 19px;
  }
  ul {
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    list-style-type: none;
    a {
      text-decoration: none;
      color: #000000;
      li {
        padding: 12px 0 22px 0;
        font-size: 17px;
        &.bottom-border{
          padding: 12px 0 12px 0;
          border-bottom: 1px solid #cdcdcd;
        }
        span {
          float: right;
          .fa {
            font-size: 20px;
          }
        }
      }
    }
  }
}

// Social icons
#sidebar-social {
  text-align: center;
  background-color: #f4f4f4;
  padding: 50px 0 50px 0;
  border-bottom: 5px solid #ffffff;
  border-right: 5px solid #ffffff;
  border-left: 5px solid #ffffff;
  p {
    font-family: Roboto-Bold, sans-serif;
    font-size: 17px;
    font-weight: bold;
    padding: 0 0 5px 0;
    text-transform: uppercase;
  }
  h3 {
    margin-bottom: 25px;
    font-size: 25px;

  }
  .social {
    width: 50px;
    height: 70px;
    background-color: black;
    padding: 23px 20px 12px 19px;
    border-radius: 50%;
    .fa {
      font-size: 30px;
      color:#fff;
      &:hover{
        animation-name: tada;
        animation-duration: 2s;
      }
    }
    &.fb{
      padding:22px 21px 13px 19px;
    }
    &.inst{
      padding: 23px 18px 13px 18px;
    }
  }
}

// Newsletter
#sidebar-newsletter{
  background-color: #f4f4f4;
  padding: 30px;
  border-right: 5px solid #ffffff;
  border-left: 5px solid #ffffff;
  position: relative;
  p{
    padding: 10px 0 0 0;
    margin: 0 0 0 0;
    line-height: 20px;
  }
  input{
    margin: 10px 0 20px 0;
  }
  a{
    margin: 30px 0 0 0;
  }
  .bold{
    font-size: 19px;
  }
  .error{
    &.show{
    font-family: Roboto-Regular, sans-serif;
    }
  }
  button[type=submit]{
    font-family: Roboto-Regular, sans-serif;
    padding: 15px 25px 15px 25px;
    font-size: 11px;
    color: #ffffff!important;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #000000;
    border: 1px solid #f9f9f9;
    transition: .3s ease;
    &:hover{
      background-color:black;
      border: 1px solid #fff;
    }
  }
  .error{
    &.show {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      border: 1px solid;
      border-radius: .25rem;
      padding: 5px;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}

// Quote
#sidebar-quote{
  text-align: center;
  padding: 22px;
  p{
    line-height: 35px;
    padding: 20px 0 20px 0;
    font-size: 20px;
    font-style: italic;

  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #contact {
  }

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {

}
