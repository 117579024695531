/* ==========================================================================
General
========================================================================== */
#contact{
  padding: 78px 0 105px 0;
  .contact-left {
    padding-right: 80px;
    .subheader {
      padding: 0 0 20px 0;
    }
    .address {
      padding: 0 0 25px 0;
    }
    p {
      padding: 0;
      margin: 0;
      span {
        font-weight: unset;
        float: right;
      }
    }
    .day{
      line-height: 25px;
    }
    strong {
      a {
        span {
          font-weight: unset;
          float: right;
        }
      }
    }
    h3 {
      padding: 45px 0 0 0;
    }
    label {
      display: none;
    }
    fieldset {
      display: block;
      width: 100%;
    }
    input, .form-control {
      margin: 5px 10px 5px 0;
      display: block;
      width: 100%;
    }
    .close{
      display: none;
    }
    textarea {
      height: 150px;
      width: 97.5%;
    }
    button {
      background-color: black;
      border: none;
      border-radius: 0;
      padding: 13px 30px 13px 30px;
      text-transform: uppercase;
      font-size: 14px;

      &::before {
        padding-right: 10px;
        content: '\f0e0';
        font-size: 17px;
        font-family: FontAwesome, sans-serif;
      }
    }
  }

  .contact-right {
    padding-left: 50px;
    .headermenu {
      background-color: #f4f4f4;
      padding: 30px;
      border: 5px solid #ffffff;
      margin: -200px 0 0 0;
      p {
        font-weight: bold;
        font-family: Roboto-Regular, sans-serif;
        padding: 0;
        margin: 0;
        font-size: 18px;
      }
      ul{
        padding: 0;
        margin: 0;
        list-style-type: none;
        li{
          text-decoration: none;
          color: #000000;
          a {
            text-transform: uppercase;
            border-bottom: 1px solid #cdcdcd;
            padding: 12px 0 17px 0;
            font-size: 16px;
            display: block;
            span {
              float: right;
              .fa{
                font-weight: bold;
                font-size: 20px;
              }
            }
          }
        }

      }
    }
    .header-social{
      text-align: center;
      background-color: #f4f4f4;
      padding: 30px;
      border-top: 5px solid #ffffff;
      border-right: 5px solid #ffffff;
      border-left: 5px solid #ffffff;
      p{
        font-family: Roboto-Regular, sans-serif;
        font-size: 15px;
        font-weight: bold;
        padding: 0 0 5px 0;
        text-transform: uppercase;
      }
      span{
        .fa{
          background-color: black;
          color:white;
          font-weight: bold;
          padding: 10px 15px 10px 15px;
          border-radius: 50%;
          font-size: 28px;
        }
      }
    }
  }
  .tour{
    position: relative;
    .tour-img{
      border-bottom: 5px solid #ffffff;
      border-right: 5px solid #ffffff;
      border-left: 5px solid #ffffff;
      width: 100%;
    }
    .tour-360{
      position: absolute;
      margin: 0 auto;
      bottom:100px;
      left:0;
      right: 0;
      max-width: 110px;
    }
    .jb-btn{
      bottom: 30px;
      margin: 0 auto;
      left:0;
      right: 0;
      max-width: 140px;
      position: absolute;
    }
  }
  .profiles {
    margin: 50px 0 50px 0;
    .profile {
      text-align: center;
      padding: 0 0 20px 0;
      overflow-x: hidden;
      overflow-y: hidden;
      max-width: 200px;
      max-height: 250px;
      img {
        width: 100%;
        height: 100%;
        padding-bottom: 10px;
        transition: 1s ease;
        &:hover{
          transform: scale(1.1);
          transition: 1s ease;
        }
      }
    }
  }


}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #contact {
  }

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #contact {
    .contact-right {
      padding-left: 0;
      .headermenu {
        margin: 0 0 0 0;
      }
    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #contact{
    .contact-left{
      .day{
        font-weight: bold;
      }
    }
    h2,h3{
      font-size: 35px;
    }
    .profiles {
      .profile {
        margin: 0 auto;
      }
    }

  }
}
