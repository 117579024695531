.js-newsletter-loader {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    border-radius: 10px;
    margin: -5px -10px;
    display: none;
}

.js-newsletter-loader.active {
    display:block;
}

.js-newsletter-loader .double-bounce1, #js-newsletter-loader .double-bounce2 {
    width: 100%;
    height: 100%;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    -webkit-animation: bounce 2.0s infinite ease-in-out;
    animation: bounce 2.0s infinite ease-in-out;
}

.js-newsletter-loader .double-bounce2 {
    animation-delay: -1.0s;
}

@-webkit-keyframes bounce {
    0%, 100% {
        -webkit-transform: scale(0)
    }

    50% {
        -webkit-transform: scale(1)
    }
}

@keyframes bounce {
    0%, 100% {
        transform: scale(0);
        -webkit-transform: scale(0)
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}
