/* ==========================================================================
General
========================================================================== */
#copyright{

  background-color: #222222;
  .copy-wrapper{
    padding: 60px 0 60px 0;
    p {
      font-size: 12px;
      margin: 0;
      color: #fff;
      a{
        color:#ffff;
        text-decoration: none;
      }
    }
  }


}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {


}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #products{
    .headerwrapper{
      h3{
        font-size: 30px;
      }
      h2{
        font-size: 40px;
      }
    }
  }
}