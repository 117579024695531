/* ==========================================================================
General
========================================================================== */
#projects-all{
  padding: 70px 0 100px 0;
  height: 100%;
  .overview-header{
    padding: 0 0 70px 0;
    h3{
      font-size: 38px;

    }
    img{
      margin: -29px 0 0 -10px;
      &:hover{
        animation-name: pulse;
        animation-duration: 1s;
      }
    }
    .form-control{
      margin: 0 0 0 54px;
      padding: 10px;
      border-radius: 1px;
      font-size: 12px;
      height: 40px;
    }
  }

  .projectrow{
    width: 100%;
    display: block;
    .projectwrapper {
      padding: 2px;
      margin: 10px -10px 0 -10px;
      max-height: 340px;
      width: 18.9%;
      float: left;
      margin: 3px 6px 6px 6px;
      overflow: hidden;
      text-align: center;
      display: block;
      position: relative;
      img {
        max-height: 340px;
        width: 100%;
        overflow: hidden;
        transition: 0.5s ease-in-out;

      }
      &:hover .jb-btn{
        transition: 2s ease;
        display: block;
      }
      .overlay{
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        display: none;
        z-index: 99;
        height: 100%;
        width: 100%;
        top:0;
        bottom:0;
        left:0;
        transition: 2s ease-in-out;
      }
      &:hover .overlay{
        display: block;
        animation-name: fadeIn;
        animation-duration: 1s;
      }
      &:hover > img{
        transform: scale(1.1);
        transition: 2s ease;
      }
    }
    .jb-btn{
      display: none;
      z-index: 999;
      text-transform: uppercase;
      font-family: Roboto-Regular, sans-serif;
      bottom: 40%;
      font-size: 15px;
      padding: 8px 5px 8px 25px;
      right: 32px;
      left: 32px;
      font-weight: lighter;
      color: #fff;
      position: absolute;
      .fa{
        left: 10px;
        top: 10px;
        font-size: 32px;
        margin-bottom: -20px;
        position: absolute;
        transition: 0.5s ease;
        &:hover > img{
          opacity: 0.9;
          transition: 0.5s ease;
        }
      }
    }
  }
  .show-more{
    padding: 50px 0 0 0;
    text-align: center;
    width: 100%;
    button{
      font-family: Roboto-Regular, sans-serif;
      padding: 15px 25px 15px 25px;
      font-size: 11px;
      color: #ffffff!important;
      font-weight: bold;
      text-transform: uppercase;
      background-color: #000000;
      border: 1px solid #f9f9f9;
      transition: .3s ease;
    }
  }




}
/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1800px) {


}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
#projects-all{
  .projectrow {
    .projectwrapper {

    }
    .jb-btn {
      padding: 5px 5px 5px 5px;
      .fa {
        display: none;
      }
    }
  }


  }
}


/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #projects-all {
    .overview-header {
      img {
        margin: 0 0 20px 0;
      }
    }
    .projectrow {
      .projectwrapper {
        width: 30.9%;
      }
      .jb-btn {
        padding: 5px 5px 5px 5px;
        .fa {
          display: none;
        }
      }

    }
  }
}


/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #projects-all {
    .overview-header{
      img{
        margin: 0 0 15px 0;
      }
      .form-control{
        margin: 0 0 0 0;
      }
    }
    .projectrow {
      .projectwrapper {
        width: 46.9%;
      }
      .jb-btn {
        padding: 5px 5px 5px 5px;
        .fa {
          display: unset;
        }
      }

      .overview-header {
        img {
          margin: 0 0 20px 0;
        }
      }
    }
  }
}


