/* ==========================================================================
General
========================================================================== */
#products{
  padding: 98px 0 95px 0;
  .headerwrapper{
    text-align: center;
    h3{
      text-transform: uppercase;
      font-size: 35px;
      padding: 0;
      margin: 0;
      font-family: Roboto-Regular, sans-serif;
    }
    h2{
      font-family: Roboto-Medium, sans-serif;
      text-transform: uppercase;
      font-size: 52px;
    span{
        font-weight: 900;
      }
    }
    p{
      padding: 30px;
    }
  }
  .productwrapper{
    padding: 0 0 10px 0;
    margin:10px -10px 0 -10px;
    max-height:340px;
    height: 100%;
    overflow: hidden;
    text-align: center;
    position: relative;
    img{
      max-height: 340px;
      width: 100%;
      overflow: hidden;
      transition: 0.5s ease-in-out;
      &:hover{
        transform: scale(1.1);
        transition: 0.5s ease-in-out;
      }
    }
    span{
      text-transform: uppercase;
      font-family: Roboto-Regular, sans-serif;
      bottom: 30px;
      font-size: 25px;
      right: 0;
      left: 0;
      font-weight: lighter;
      color: #fff;
      position: absolute;
    }
  }


}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {


}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #products{
    .headerwrapper{
      h3{
       font-size: 30px;
      }
      h2{
        font-size: 40px;
      }
    }
  }
}