/* ==========================================================================
General
========================================================================== */

#footer {
    background-image: url("../images/jb-transparant-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 330px;
    background-color: #000;
    color: #FFF;
    padding: 100px 0;
    .contact-data {
        position: relative;
        .image-wrapper {
            img{
                position:absolute;
                top: 0;
                left: 0;
                width: 55px;
                height: auto;
                &:hover{
                    animation-name: tada;
                    animation-duration: 2s;
                }
            }
        }
        .contact-info {
            margin-left: 40px;
            ul {
                list-style: none;
                li {
                    color:#ffff;
                    font-family: Roboto-Regular, sans-serif;
                    font-size: 14px;

                    &.strong {
                        font-weight: 700;
                    }
                }
                a{
                    color:#ffff;
                }
            }
        }
        .time-info {
        ul {
            list-style: none;
            padding-left: 0px;
            li {
                color:#ffff;
                font-family: Roboto-Regular, sans-serif;
                font-size: 14px;
                &.strong {
                    font-weight: 700;
                }
            }

            }
        }
    }

    .buttons-wrapper {
        border-bottom: 1px solid #EEE;
        height: 100px;
        a {
            text-decoration: none;
            &:hover {
                color: white;
            }
        }
    }

    p{
        margin: 0;
        padding: 0;
    }
    .images-wrapper {
        img{
            height: 25px;
            margin-right: 10px;
            width: auto;
            float: left;

        }
    }
    #scrollup{
        text-align: center;
        width: 70px;
        padding: 16px 0 0 0;
        height: 70px;
        background-color: #222222;
        float: right;
        margin: 30px 53px 0 0;
        .fa{
            color:#fff;
            font-size: 35px;
            font-weight: bold;
            &:hover{
                animation-name: pulse;
                animation-duration: 2s;
            }
        }

    }
}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {


}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
#footer {
    background-position-x: 100px;
        .contact-data {
            .image-wrapper {
                img {
                    left: 0;

                }
            }
            .col-lg-3 {
                ul {
                    float: left;
                    margin-right: 10px;
                }
            }
        }
        .buttons-wrapper{
            margin: 30px 0 0 0;


        }
    }
    #scrollup{
        margin: -14px 53px 0 0;
    }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
#footer {
    background-position-x: 0;
    padding: 55px 0;
    .contact-data {
        .image-wrapper {
            img {
                left: 20px;
            }
        }
        .contact-info {
            margin-left: 0;
            ul {
                margin: 10px 0 0 0;
                padding: 0;
                text-align: center;
            }
        }
        .time-info {
            margin: 20px 0 0 0;
            ul {
                margin: 0;
                padding: 0;
                text-align: center;
                li {
                    font-size: 15px;
                }
            }
        }
    }
    .buttons-wrapper{
        text-align: center;
        }
    }

}
