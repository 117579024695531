
.jb-btn {
    font-family: Roboto-Regular, sans-serif;
    padding: 15px 20px 15px 20px;
    font-size: 11px;
    color: #ffffff!important;
    font-weight: bold;
    text-transform: uppercase;
    &:hover{
        color:#fff;
        text-decoration: none;
    }
    &.grey {
        background-color: #444444;
        border: 1px solid #444444;
        transition: .3s ease;
        color: #ffffff;

    }
    &.black {
        background-color: #000000;
        border: 1px solid #000000;
        transition: .3s ease;
        color: #ffffff;
    }
}

.btn-primary{
    font-family: Roboto-Regular, sans-serif;
    padding: 15px 25px 15px 25px;
    font-size: 11px;
    color: #ffffff!important;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #000000;
    border: 1px solid #f9f9f9;
    transition: .3s ease;
    &:hover{
        background-color:black;
        border: 1px solid #fff;
    }
}
