/* ==========================================================================
General
========================================================================== */
#breadcrumbs{
  text-transform: uppercase;
  font-family: Roboto-Regular, sans-serif;
  position: absolute;
  width: 100%;
  height:56px;
  background: rgba(0,0,0,0.5);
  margin: -56px 0 0 0;
  font-size: 14px;
  p{
    color: #fff;
    font-size: 15px;
    margin: 6px 0 0 0;
    text-transform: uppercase;
    a{
      color: #fff;
    }
  }
  .breadcrumb{
    line-height: 56px;
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    flex-wrap: nowrap;
    background-color: transparent;
    border-radius: 0;
    .crumb{
      padding: 0 5px 0 10px;
      float: left;
      color:#fff;
      a, span {
        color:#fff;
        text-decoration: none;
      }
      &.active{
        padding: 0 5px 0 0;
        font-size: 14px;
        &:before{
          content:'·';
          color:#fff;
        }
      }
    }
    &::after {
      content: '';
      display: block;
      clear: both;
    }
    &:before{
      content:'U bent hier:';
      color:#fff;
      float: left;
    }

  }

}
/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1920px) {
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
}