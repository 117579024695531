/* ==========================================================================
Libraries
========================================================================== */
$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';
@import '~bootstrap/scss/bootstrap';
@import '~animate.css/animate.css';
@import '~swiper/dist/css/swiper.min.css';
@import '~fancybox/dist/css/jquery.fancybox.css';

/* ==========================================================================
Plugins
========================================================================== */
@import 'share';

/* ==========================================================================
Imports
========================================================================== */
@import 'editor';
@import 'fonts';
@import 'colors';
@import 'buttons';
@import 'header';
@import 'subpage-header';
@import 'menu';
@import 'breadcrumbs';
@import 'footer';

// Home
@import 'quality';
@import 'employees';
@import 'products';
@import 'news';
@import 'copyright';

// Sidebar
@import 'sidebar';

// Contact
@import 'contact';
@import 'service';

// Project overview
@import 'project-all';
@import 'advice';

// News overview
@import 'news-all';
@import "optinform";

// News detail
@import 'news-detail';

//Shop
@import 'shop';
@import 'photoalbum';

//Product
@import 'product';
@import 'projects';
@import 'producttypes';
@import 'productdetail';
