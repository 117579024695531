/* ==========================================================================
General
========================================================================== */
#employees{
  padding: 70px 0;
  background-color: #f4f4f4;
  .employee-left{
    padding:0 50px 0 0;
    h2{
      font-family: Roboto-Regular, sans-serif;
      font-size: 33px;
      padding:0 0 10px 0;
      span{
        font-weight: 700;
      }
    }
  }
  .secondrow{
    padding: 10px 0 0 0;
  }


}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {


}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #employees {
    padding: 70px 0;
    background-color: #f3f7fa;
    .employee-left {
      padding: 0 0 0 0;
    }
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {

}