/* ==========================================================================
General
========================================================================== */
#news{
 .greyblock{
   padding: 20px 20px 40px 20px;
   background-color: #444444;
    .newsblock{
      padding: 40px 20px 40px 0;
      border-bottom: 1px solid #7c7c7c;
      float:right;
      padding-right: 30px;
      padding-left: 100px;
      max-width: 500px;
      position: relative;
      .monthday{
        position: absolute;
        left:0;
        top:25px;
        text-align: center;
        .month{
          line-height: 0px;
          font-size: 33px;
          font-family: Roboto-Bold, sans-serif;
        }
        .day{
          font-size: 40px;
        }
      }
      .header{
        font-family: Roboto-Bold, sans-serif;
      }
      .introduction{
        max-height: 100px;
        overflow: hidden;
      }
      p{
        color:#ffff;
        margin: 0;
      }
    }
  }
  .whiteblock{
    padding: 50px 0 100px 100px;
    .textwrapper {
      max-width: 600px;
      max-height: 400px;
      h2 {
        font-size: 33px;
        font-style: italic;
      }
    }
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {


}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
#news{
  .greyblock{
    .newsblock{
      max-width: 100%;
    }
  }
  .whiteblock{
    padding: 49px 20px 100px 13px;
  }
}
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #news {
    .whiteblock{
      padding: 50px 0 0 20px;
      .textwrapper{
        max-width: 100%;
        max-height: 100%;
      }
      p{
        padding: 20px 0 50px 0;
      }
    }
  }
}