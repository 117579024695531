/* ==========================================================================
General
========================================================================== */
.subpage-header{
  height:800px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: top center;
  &::after {
    content: '';
    display: block;
    background-image: url('../images/shadow.jpg');
    background-size: auto 100%;
    background-position: left center;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    width: 100%;
    height: 56px;
    background-repeat: no-repeat;
  }
  &.contact {
    // background-image: url("../images/contact-header.png");
    .jbmap{
      z-index: -1;
      height: 800px;
    }
  }
  &.projects {
    background-image: url("../images/projectoverviewheader.jpg");
  }
  &.newsoverview{
  }
  &.shop{
    background-image: url("../images/shop-header.jpg")
  }
  &.product{
    background-image: url("../images/header-curtains.jpg")
  }
  .header-wrapper {
    margin: 0;
    padding: 500px 0 50px 0;
    position: relative;
    .headertitle {
      text-align: left;
      float: left;
      h4 {
        font-weight: 200;
        text-transform: uppercase;
        text-align: left;
        color: #fff;
        font-size: 30px;
        span {
          font-weight: bold;
          border-bottom: 2px solid rgba(255, 255, 255, 0.8);
        }
      }
      h2 {
        font-weight: 100;
        text-transform: uppercase;
        color: #fff;
        font-size: 70px;
        span {
          font-weight: bold;
        }
      }
    }
  }
    .pop-up {
      background-color: black;
      padding: 13px 36px 13px 36px;
      position: fixed;
      right: -142px;
      color: #ffff!important;
      text-decoration: none;
      font-family: Roboto-Regular, sans-serif;
      transform: rotate(270deg);
      border: 2px solid #ffff;
      top: 446px;
      transition: 1s ease;
      cursor: pointer;
      z-index: 999;
      &:hover{
        transition: 0.5s ease;
        color: #cbcbcb!important;
      }
      &.open {
        transition: 2s ease;
      }

    }
    .showform{
      display: none;
      max-height: 339px;
      border: 2px solid #ffffff;
      height: 100%;
      max-width: 300px;
      position: fixed;
      animation-name: slideOutRight;
      animation-duration: 2s;
      z-index: 999999;
      right:52px;
      top: 304px;
      padding: 30px 20px 40px 20px;
      background-color: black;
      input{
        margin: 5px;
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-family: Roboto-Regular, sans-serif;
        color: #000000;
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: Roboto-Regular, sans-serif;
        color: #000000;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        font-family: Roboto-Regular, sans-serif;
        color: #000000;
      }
      .jb-submit{
        border-radius: 5px;
        background-color: #000000;
        border: 1px solid #ffffff;
        color:white;
        padding: 10px;
        font-family: Roboto-Regular, sans-serif;
        transition: 0.5s ease;
        &:hover{
          background-color: #ffffff;
          color: #000000;
          transition: 0.5s ease;
        }
      }
      p{
        font-size: 10px;
        color: #fff;
        padding: 0;
        margin: 0;
      }
      h3{
        color:#ffff;
        font-size: 23px;
      }
      &.active{
        display: unset;
        animation-name: slideInRight;
        animation-duration: 2s;
      }
      label{
        display: none;
      }
    }
    .swiper-container{
      z-index: -1;
      padding: 100px 0 0 0;
      height: 800px;
      .swiper-wrapper{
        .swiper-slide{
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
}
/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1920px) {
.subpage-header{
  height: 600px;
    &.contact{
        .jbmap{
            height: 600px;
        }
    }
    .opening{
      background-color: rgba(255, 255, 255, 0.3);
      padding: 11px;
      border-radius: 4px;
      animation-name: fadeIn;
      animation-duration: 0.5s;
    }
    .swiper-container{
      height: 600px;
      padding: 0;
    }
  }

}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  .subpage-header{
      height: 550px;
      .headertitle{
        h2{
          font-size: 63px;
        }
      }
      .swiper-container{
        height: 550px;
        padding: 0;
      }
    &.contact .jbmap {
      height: 550px;
    }
    }
    .opening{
      background-color: rgba(255, 255, 255, 0.5);
      padding: 11px;
      border-radius: 4px;
    }
}


/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
   .subpage-header{
     height: 400px;
     .headertitle{
       h4{
         font-size: 44px;
       }
       h2{
         font-size: 48px;
       }
     }
     .swiper-container {
       height: 400px;
       padding: 0;
     }
     &.contact .jbmap {
       height: 400px;
     }
   }
   .opening{
     background-color: transparent;
     margin: 0 0 0 0;
     left: 0;
   }
 }


/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  .subpage-header{
    height:200px;
    margin-top: 65px;
    &.contact{
      .jbmap{
        height: 200px;
      }
    }
    .headertitle{
      text-align: center;
      h4{
        text-align: center;
        font-size: 24px;
      }
      h2{
        font-size: 28px;
      }
    }
    .swiper-container{
      height: 200px;
      padding: 0;
    }  .opening{
         text-align: center;
         left: 0;
       }
    .pop-up{
      padding: 5px 34px 5px 34px;
      position: fixed;
      right: -153px;
      top: 226px;
    }
    .showform{
      max-height: 333px;
      top: 78px;
      right: 31px;
    }
  }
}


