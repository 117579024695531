/* ==========================================================================
General
========================================================================== */
#shop{
  padding: 50px 0 50px 0;
  .shopcontent{
    padding-right: 50px;
    h5{
      text-transform: uppercase;
      font-family: Roboto-Regular, sans-serif;
    }
    h2{
      font-size: 55px;
    }
    img{
      max-height: 180px;
    }
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #shop {
  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #shop {
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #shop{

  }
}