/* ==========================================================================
General
========================================================================== */
#service{
  padding: 0 0 105px 0;
  .line{
    height: 5px;
    width: 100%;
    background-color: #f9f9f9;
  }
  .service-wrapper{
    text-align: center;
    h3{
      font-style: italic;
      padding: 85px 0 0 0;
      text-transform: unset;
      font-size: 35px;
    }
    p{
      font-size: 16.5px;
    }
  }

}

.service-home{
  #service {
    .line {
      display: none;
    }
    .service-wrapper {
      h3 {
        padding: 0 0 0 0;
      }
    }
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #service {
  }

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #service {
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #service{

  }
}