/* ==========================================================================
General
========================================================================== */
#product{
  padding: 100px 0 100px 0;
  .product-right{
    .headermenu{
      margin: -200px 0 0 0;
      background-color: #f4f4f4;
      padding: 20px 30px 50px 30px;
      border-top: 5px solid #ffffff;
      border-right: 5px solid #ffffff;
      border-left: 5px solid #ffffff;
      border-bottom: 5px solid #ffffff;
      p{
        padding: 10px 0 0 0;
        margin: 0 0 0 0;
        line-height: 20px;
      }
      input{
        margin: 10px 0 20px 0;
      }
      a{
        margin: 30px 0 0 0;
      }
      .bold{
        font-size: 19px;
      }
      ul {
        text-transform: uppercase;
        padding: 0;
        margin: 0;
        list-style-type: none;
        a {
          text-decoration: none;
          color: #000000;
          li {
            padding: 12px 0 30px 0;
            font-size: 17px;
            &.bottom-border{
              padding: 12px 0 17px 0;
              border-bottom: 1px solid #cdcdcd;
            }
            span {
              float: right;
              .fa {
                font-weight: bold;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .sidebar-products{
      background-color: #f4f4f4;
      padding: 20px 30px 30px 30px;
      border-right: 5px solid #ffffff;
      border-left: 5px solid #ffffff;
      border-bottom: 5px solid #ffffff;
      p{
        font-weight: bold;
        line-height: unset;
        margin: 0 0 40px 0;

      }
      ul{
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          text-transform: uppercase;
          border-bottom: 1px solid #cdcdcd;
          padding: 12px 0 17px 0;
          font-size: 17px;
          a{
            text-decoration: none;
            color: #000000;
            &::before{
              content: '\f105';
              font-family: FontAwesome, sans-serif;
              font-size: 21px;
              font-weight: bold;
              margin: -5px 0 0 0;
              float:right;
            }
            span {
              float: right;
              .fa{
                font-weight: bold;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .checkmark{
      padding: 40px 0 0 0;
      text-align: right;
    }
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {


}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
#product{
  .product-left{
    h2{
      font-size: 30px;
    }
  }
}
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #product{
    .product-left{
      h2{
        font-size: 25px;
      }
    }
  }
}