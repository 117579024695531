/* ==========================================================================
General
========================================================================== */
#producttypes{
  background-color: #f4f4f4;
  padding: 110px 0 100px 0;
  .type-header{
    text-align: center;
    width: 100%;
    padding: 0 0 60px 0;
  }
  #company-slide{
    .swiper-slide{
      .img-responsive{
        max-height: 70px;
        max-width: 100%;
        display: block;
        margin: 0 auto;

      }
    }
  }
  .products{
    padding: 80px 0 0 0;
    .productwrapper{
      padding: 0 0 10px 0;
      margin:10px -10px 0 -10px;
      max-height:340px;
      height: 100%;
      overflow: hidden;
      text-align: center;
      position: relative;
      img{
        max-height: 340px;
        width: 100%;
        overflow: hidden;
        transition: 0.5s ease-in-out;
        &:hover{
          transform: scale(1.1);
          transition: 0.5s ease-in-out;
        }
      }
      span{
        text-transform: uppercase;
        font-family: Roboto-Regular, sans-serif;
        bottom:30px;
        font-size: 28px;
        right: 0;
        left: 0;
        font-weight: lighter;
        color:#fff;
        position: absolute;

      }
    }


  }

}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {


}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #producttypes {
  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #producttypes {
  }
}