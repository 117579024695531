/* ==========================================================================
Editor
========================================================================== */
.redactor-editor, .redactor-box {
    background: transparent !important;
}

a{
    color: #000000;
    text-decoration: none;
    font-family: Roboto-Regular, sans-serif;
    &:hover{
        color: #282828;
        text-decoration: none;
    }
}
ul{
    li{
        font-family: Roboto-Regular, sans-serif;
    }
}
p{
    font-family: Roboto-Regular, sans-serif;
    line-height: 2.2;
    font-size: 16px;
    span{
        font-family: Roboto-Medium, sans-serif;
    }
}

h2{
    font-family: Roboto-Medium, sans-serif;
    font-size: 60px;
    text-transform: uppercase;
    font-weight: 100;
    span{
        font-family: Roboto-Bold, sans-serif;
    }
}

h3{
    font-family: Roboto-Medium, sans-serif;
    text-transform: uppercase;
    font-size: 40px;
    span{
        font-family: Roboto-Bold, sans-serif;
    }
}

h4{
    font-family: Roboto-Medium, sans-serif;
    text-transform: uppercase;
}

span{
    font-family: Roboto-Regular, sans-serif;
    &.bold{
        font-family: Roboto-Bold, sans-serif;
    }
}

// Editable screen
.redactor-box {
    background: transparent;
    color:black;
    .redactor-editor{
        background: transparent;
    }
}

.staticpage{
    padding: 78px 0 100px 0;

}
.shadow{
    position: absolute;
    left:10%;
    width: 50%;
    height:45px;
    box-shadow: none !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d8d8d8;
    font-size: 10px;
    letter-spacing: 1px;
    font-family: Roboto-Regular, sans-serif;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #d8d8d8;
    font-size: 10px;
    letter-spacing: 1px;
    font-family: Roboto-Regular, sans-serif;
    opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #d8d8d8;
    font-size: 10px;
    letter-spacing: 1px;
    font-family: Roboto-Regular, sans-serif;
    opacity: 1; /* Firefox */
}

/* ==========================================================================
Huge Desktop
========================================================================== */
@media screen and (max-width: 1920px) {

}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {

}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {

}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
    .shadow{
        bottom:unset;
    }
    .staticpage{
        padding: 50px 0 50px 0;
        h2{
            font-size: 40px;
        }
    }
}
