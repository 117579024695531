/* ==========================================================================
General
========================================================================== */
#quality{
  padding: 100px 0 104px 0;
  h2{
    font-size: 33px;
    text-transform: unset;
    span{
      font-weight: 700;
      font-family: Roboto-Medium, sans-serif;
    }
  }
  p{
    padding: 20px 0 30px 0;
    font-weight: 100;

  }
  .checkmark {
    .checkmark-black {
      position: absolute;
      &:hover {
        animation-name: tada;
        animation-duration: 2s;
      }
    }
    p {
      font-size: 13px;
      padding: 4px 0 0 50px;
      font-family: Roboto-Regular, sans-serif;

    }
  }
}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {


}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #quality{
    p{
      padding:20px 0 0 0;
    }

  }
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #quality{
    .checkmark{
      margin: 20px 0 0 0;
    }

  }
}