/* ==========================================================================
General
========================================================================== */
#news-all{
  padding: 78px 0 105px 0;
  h3{
    font-size: 32px;
  }
  .newsblock{
    padding: 27px 0px 15px 0;
    border-bottom: 2px solid #d8d8d8;
    .monthday{
      padding: 5px;
      text-align: center;
      background-color: #000000;
      margin: 10px 5px 0 5px;
      p{
        color:#fff;
        font-size: 30px;
        line-height: 40px;
      }
      .day{
        font-family: Roboto-Regular, sans-serif;
      }
    }
    .header{
      font-family: Roboto-Bold, sans-serif;
    }
    a{
      font-weight: bold;
    }
    .introduction{
      max-height: 100px;
      overflow: hidden;
    }
    p{
      color: #000000;
      margin: 0;

    }
  }
  .show-more{
    padding: 70px 0 50px 0;
  }
  .news-right {
    padding-left: 50px;
    .headermenu {
      background-color: #f4f4f4;
      padding: 30px;
      border: 5px solid #ffffff;
      margin: -200px 0 0 0;
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        a {
          text-decoration: none;
          color: #000000;
          li {
            border-bottom: 1px solid #cdcdcd;
            padding: 12px 0 12px 0;
            font-size: 19px;
            span {
              float: right;
              .fa {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .header-newsletter{
      background-color: #f4f4f4;
      padding: 30px;
      border: 5px solid #ffffff;
      p{
        padding: 10px 0 0 0;
        margin: 0 0 0 0;
        line-height: 20px;
      }
      input{
        margin: 10px 0 20px 0;
      }
      a{
        margin: 30px 0 0 0;
      }
    }
    .sidebar-facebook{
      background-color: #f4f4f4;
      padding: 30px;
      border-right: 5px solid #ffffff;
      border-left: 5px solid #ffffff;
    }
  }
}
/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1800px) {


}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
#news-all{
  .news-right{
    .sidebar-facebook{
      padding: 10px 0px 10px 0px;
    }
  }
}

}


/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #news-all{
    .news-right {
      padding-left: 0;
      .headermenu {
        margin: 0 0 0 0;
      }
      .sidebar-facebook{
        padding: 20px;
        text-align: center;
      }
    }
  }
}


/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #news-all {
    .news-left{
      .opening{
        padding-right: 50px;
      }
    }
    h2,h3{
      font-size: 35px;
    }
  }

}



