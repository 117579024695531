/* ==========================================================================
General
========================================================================== */
#projects{
  .line{
    height: 5px;
    width: 100%;
    background-color: #f9f9f9;
  }
  .projectheader{
    padding: 80px 0 50px 0;
    text-align: center;
    width: 100%;
    h3{
      padding-left: 160px;
    }
    .pinterest{
      margin: -40px 0 0 30px;
      &:hover{
      animation-name: pulse;
      animation-duration: 1s;
      }
    }
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .swiper-container {
    width: 100%;
    .swiper-wrapper {
      width: 100%;
      .swiper-slide {
        margin: 0 30px 0 0px;
        width: 100%;
        .projectwrapper {
          max-height: 340px;
          width: 100%;
          overflow: hidden;
          img {
            max-height: 340px;
            width: 100%;
            transition: 0.5s ease-in-out;
          }
          &:hover .jb-btn {
            transition: 2s ease;
            display: block;
          }
          .overlay {
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            display: none;
            z-index: 99;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            transition: 2s ease-in-out;
            animation-name: fadeOut;
            animation-duration: 1s;
          }
          &:hover .overlay {
            display: block;
            animation-name: fadeIn;
            animation-duration: 1s;
          }
          &:hover > img {
            transform: scale(1.1);
            transition: 2s ease;
          }
        }
      }
      .jb-btn {
        display: none;
        z-index: 999;
        max-width: 300px;
        text-transform: uppercase;
        font-family: Roboto-Regular, sans-serif;
        bottom: 40%;
        font-size: 15px;
        padding: 8px 0px 8px 42px;
        right: 30%;
        left: 30%;
        font-weight: lighter;
        color: #fff;
        position: absolute;
        .fa {
          left: 10px;
          top: 10px;
          font-size: 32px;
          margin-bottom: -20px;
          position: absolute;
          transition: 0.5s ease;
          &:hover {
            opacity: 0.9;
            transition: 0.5s ease;
          }
        }
        &:hover {

        }
      }
    }
  }



}


/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {
  #projects {

  }
}

/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
#projects{
  .projectheader{
    .pinterest{
      margin: 10px 0 0 30px;
    }
    h3{
     padding-left: 0;
    }
  }

}
}

/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #projects {

  }
}