.flitzshare-icons {
  .icons .icon {
    background: #000 !important;
    height: 50px !important;
    width: 50px !important;
    .fa {
      color: #fff !important;
      display: block !important;
      line-height: 50px !important;
    }
  }
}