/* ==========================================================================
General
========================================================================== */
#news-detail{
  padding: 78px 0 105px 0;
  .news-left {
    h3 {
      font-size: 36px;
    }

    .newsblock {
      padding: 27px 0 15px 0;
      .monthday {
        padding:3px 5px 5px 5px;
        text-align: center;
        background-color: #000000;
        p {
          color: #fff;
          font-size: 30px;
          line-height: 40px;
        }
        .day {
          font-family: Roboto-Regular, sans-serif;
        }
      }
      .header {
        font-size: 34px;
        line-height: 40px;
      }
      .introduction {
        max-height: 100px;
        overflow: hidden;
      }
      p {
        color: #000000;
        margin: 0;

      }
    }
    .share {
      padding: 50px 0 50px 0;
      h3 {
        margin-bottom: 25px;
        font-size: 25px;

      }
    }
  }
  .news-right {
    padding-left: 50px;
    .headermenu {
      position: relative;
      background-color: #f4f4f4;
      padding: 75px 30px 30px 30px;
      border-top: 5px solid #ffffff;
      border-right: 5px solid #ffffff;
      border-left: 5px solid #ffffff;
      margin: -200px 0 0 0;
      .news-return{
        position: absolute;
        top:0;
        right:0;
        left:0;
        padding: 10px 10px 5px 25px;
        background-color: #000000;
        h4{
          line-height: 21px;
          font-size: 12px;
          color:#fff;

        }
        .fa{
          float:left;
          font-size: 20px;
          padding-right: 20px;
        }
      }
      .bold{
        font-size: 19px;
        margin: 20px 0 0 0;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        a {
          text-decoration: none;
          color: #000000;
          li {
            border-bottom: 1px solid #cdcdcd;
            padding: 12px 0 12px 0;
            font-size: 19px;
            span {
              float: right;
              .fa {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .checkmark{
      padding: 40px 0 0 0;
      text-align: right;
    }
  }
}
/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1800px) {


}

/* ==========================================================================
Desktop
========================================================================== */
@media screen and (max-width: 1200px) {

}


/* ==========================================================================
Tablet
========================================================================== */
@media screen and (max-width: 992px) {
  #news-detail {
    .news-right {
      padding-left: 0;
      .headermenu {
        margin: 0 0 0 0;
      }
    }
  }
}


/* ==========================================================================
Mobile
========================================================================== */
@media screen and (max-width: 767px) {
  #news-detail {
    .news-left{
      .opening{
        padding-right: 50px;
      }
    }
    h2,h3{
      font-size: 35px;
    }
  }
}


